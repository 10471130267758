import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { toPlainText } from "../lib/helpers";
import {
  PromptList,
  ResourcesHeader,
  SectionWrapper,
} from "../components/sections";

export const query = graphql`
  query SignalSourceTemplateQuery($id: String!) {
    category: sanitySignalSource(id: { eq: $id }) {
      _type
      _id
      status
      title
      headline
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...SanityImage
      }
      slug {
        current
      }
    }
    # signals: allSanitySignal(
    #   sort: { fields: [_createdAt], order: DESC }
    #   filter: {
    #     slug: { current: { ne: null } }
    #     signalSource: { elemMatch: { id: { in: [$id] } } }
    #     status: { eq: "published" }
    #   }
    # ) {
    #   edges {
    #     node {
    #       ...cardNodeSignal
    #     }
    #   }
    # }
    # prompts: allSanityPrompt(
    #   sort: { fields: [_createdAt], order: DESC }
    #   filter: {
    #     slug: { current: { ne: null } }
    #     categories: { elemMatch: { id: { in: [$id] } } }
    #     status: { eq: "published" }
    #   }
    # ) {
    #   edges {
    #     node {
    #       ...cardNodePrompt
    #     }
    #   }
    # }
  }
`;

const SignalSourceTemplate = ({ data = {}, pageContext }) => {
  // const site = useContext(SiteContext);
  const { title, _rawExcerpt, mainImage } = data.category || {};
  // const theDocs = mapEdgesToNodes(data.signals).concat(mapEdgesToNodes(data.prompts))
  // .sort((a, b) =>
  //   (a.publishedAt || a._createdAt) < (b.publishedAt || b._createdAt) ? 1 : -1
  // );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutContainer
      doc={{ ...data.category, backgroundColor: "lightLavender" }}
    >
      <PageHeadMeta
        title={title}
        description={toPlainText(_rawExcerpt)}
        image={mainImage}
        doc={data.category}
      />
      <article>
        <SectionWrapper layout="centered" padding="tight" container="thin">
          <ResourcesHeader title={title} tag={data.category} />
        </SectionWrapper>
        <SectionWrapper background="lavenderUltraLight" padding="tight">
          <PromptList tag={data.category} cols={3} />
          <div className="h-12 lg:h-24"></div>
        </SectionWrapper>
      </article>
    </LayoutContainer>
  );
};

export default SignalSourceTemplate;
